import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { Image } from './Images'
import { media } from './Styles'

import logo from "../images/misc/Logo-CantoSuperiorDireito.svg"
import pt2020img from "../images/pt2020w.png"
import more from "../images/nav/more.svg"

const MainBanner = ({ data, white, pt2020, projects }) => (
    <StyledBanner white={white}>
        <Image
            src={data.slider}
            alt='Ponto Urbano'
        />
        <div className='content'>
            {pt2020 &&
                <div className='pt2020'>
                    <a href="/Ponto_Urbano-Ficha_projeto.pdf" target="_blank" rel="nofollow">
                        <img src={pt2020img} alt="PT2020" />
                    </a>
                </div>
            }
            <div className='logo'>
                <Link to='/' className="logoHome">
                    <img src={logo} alt="logo" />
                </Link>
            </div>
            <div className='title'>
                <h1 dangerouslySetInnerHTML={{ __html: data.title }}></h1>
            </div>
            {projects &&
                <>
                    <div className='projects'>
                        <h4 dangerouslySetInnerHTML={{ __html: data.subtitle }}></h4>
                        <Link to='/projetos'>
                            <img src={more} alt="More" />
                        </Link>
                    </div>
                    <div className='line'></div>
                </>
            }
        </div>
    </StyledBanner>
)

export default MainBanner

const StyledBanner = styled.div`
    position:relative;
    height:100vh;

    .image{
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;

        ${props => props.white ?
        `
            ::before{
                content:'';
                position:absolute;
                top:0;
                left:0;
                width:100%;
                height:100%;
                z-index: 1;
                background: rgb(255,255,255);
                background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 20%, rgba(255,255,255,0.5018382352941176) 80%);
            }
            `
        :
        `
            ::before{
                content:'';
                position:absolute;
                top:0;
                left:0;
                width:100%;
                height:100%;
                background-color: #2c2c2c;
                opacity: 0.3;
                z-index: 1;
            }
            `
    }        
    }

    .content{
        position:relative;
        padding: 0 7.3%;
        width:100%;
        height:100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align:center;
        color:${props => props.white ? '#000' : '#fff'};

        .pt2020{
            position: absolute;
            top: 5%;
            right: 5%;
            transform: translate(-5%, -5%);

            ${media.m`
                top: 10%;
                transform: translate(-10%, -5%);
            `}

            img{
                width:100%;
                max-width:300px;

                ${media.l`
                    max-width:250px;
                `}

                ${media.s`
                    max-width:200px;
                `}
            }
        }

        .logo{
            position: absolute;
            top: 20%;
            transform: translateY(-20%);

            ${media.m`
                top: 25%;
                transform: translateY(-25%);
            `}

            img{
                width:100%;
                max-width:200px;
                ${props => props.white && `filter:invert(1)`};

                ${media.l`
                    max-width:150px;
                `}

                ${media.s`
                    max-width:100px;
                `}
            }
        }

        h1{
            font-weight:400;
            font-size:3.5rem;

            b{
                font-weight:600;
            }

            ${media.l`
                font-size:2.5rem;
            `}

            ${media.s`
                font-size:2rem;
            `}
        }

        .projects{
            position: absolute;
            bottom: 15%;
            transform: translateY(-15%);
            display: flex;
            flex-direction: column;
            gap: 1.5rem;

            ${media.smarttv`
                gap:0;
            `}

            ${media.m`
                bottom: 10%;
                transform: translateY(-10%);
            `}

            h4{
                text-transform:uppercase;
                line-height: 2rem;
                font-size: 1.1rem;

                ${media.smarttv`
                    margin-bottom:1.5rem;
                `}
            }

            a{
                width: fit-content;
                margin: 0 auto;
                
                img{
                    max-width:45px;
                    border-radius:50%;
                    transition: 0.3s;

                    :hover{
                        background: #ebebeb80;
                    }
                }
            }
        }

        .line{
            position: absolute;
            bottom: 0;
            height: 70px;
            border-right: 1px solid #fff;

            ${media.s`
                height:40px;
            `}
        }
    }
`
